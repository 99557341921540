import { Box, Stack } from "@mui/material";

export type SectionHeaderAction = {
  label: React.ReactComponentElement<any, any> | string;
  onClick: () => void;
};

export const SectionHeader: React.FC<{
  title: string;
  rightAction?: SectionHeaderAction;
}> = ({ title, rightAction }) => {
  return (
    <h3
      style={{
        fontSize: "16px",
        fontFamily: "Open Sans",
        color: "#222222",
        fontWeight: "normal",
        padding: "12px 8px 4px 8px",
        borderBottom: "1px solid #f9d0a9",
        margin: "0",
      }}
      onClick={rightAction?.onClick}
    >
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"top"}
      >
        {title}
        {rightAction && (
          <Box
            style={{
              cursor: "pointer",
              color: "#f9d0a9",
            }}
            onClick={rightAction.onClick}
          >
            {rightAction.label}
          </Box>
        )}
      </Stack>
    </h3>
  );
};
