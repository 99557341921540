import { Stack, useMediaQuery } from "@mui/material";
import { pageWidth } from "src/theme";
import theme from "src/theme";

const PageWidthContainer: React.FC<{
  fullWidth?: boolean;
  children: React.ReactNode;
}> = ({ fullWidth, children }) => {
  const wide = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack direction="row" justifyContent="center" maxWidth={"100vw"}>
      <Stack
        width={pageWidth}
        maxWidth={"100%"}
        gap={2}
        paddingX={fullWidth || !wide ? 0 : "1rem"}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default PageWidthContainer;
