import { createTheme } from "@mui/material";
import colors from "./colors";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { forwardRef } from "react";

export const pageWidth = 1060;

const body = {
  fontFamily: "Open Sans",
  fontSize: 12,
};

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  palette: {
    text: {
      primary: colors.gray[900],
    },
    primary: {
      main: colors.orange[300],
    },
    secondary: {
      main: colors.accent[900],
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: { fontWeight: 600, fontSize: 28 },
    h2: { fontWeight: 600, fontSize: 24, marginTop: 32, marginBottom: 14 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
    subtitle1: { fontWeight: 600 },
    subtitle2: { fontWeight: 600 },
    body1: body,
    body2: body,
    caption: body,
    button: {
      ...body,
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: pageWidth,
      xl: 1920,
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          ...body,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          fontSize: 20,
          color: colors.gray[900],
          textDecoration: "underline",
          "&:hover": {
            color: colors.indigo[700],
          },
        },
      },
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: "100%",
          borderCollapse: "separate",
          borderSpacing: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          borderBottom: "none",
          padding: "6px",
          fontSize: 12,
        },
        head: {
          borderBottom: `1px solid ${colors.orange[300]}`,
          backgroundColor: "#ffffff",
          fontWeight: 600,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#f9f9f9",
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-body1": {
            marginBottom: "0.75em",
            marginTop: "0.75em",
            "&:last-child": {
              marginBottom: 0,
            },
          },
        },
        paragraph: {
          marginBottom: "0.75em",
          marginTop: "0.75em",
        },
      },
    },
  },
});

export default theme;