import React, { useEffect } from "react";
import { Box, Skeleton, Stack, useMediaQuery } from "@mui/material";
import PageWidthContainer from "./PageWidthContainer";
import PageHeader, { PageHeaderConfigKey } from "./PageHeader";
import { Link } from "react-router-dom";
import SidebarItem, { SidebarItemData } from "./SidebarItem";
import theme from "src/theme";

const RightSidebar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <Box width={247}>{children}</Box>;
};

export type Crumb = {
  title: string;
  url?: string;
};

export const Ingress: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <p style={{ fontSize: "16px", marginBottom: "16px" }}>{children}</p>;
};

const ReviewWidget = () => (
  <Stack mt={4} alignItems={"center"} direction={"column"}>
    <div id="widget-reviews"></div>
    <Box width="100%" height="1px" bgcolor="#e0e0e0" my={2} />
    <Link
      to="https://www.utbildning.se/"
      style={{ color: "#444", textDecoration: "none" }}
    >
      I samarbete med utbildning.se
    </Link>
  </Stack>
);

export const Page: React.FC<{
  title?: string;
  header?: PageHeaderConfigKey;
  sidebar?: SidebarItemData[];
  right_sidebar?: React.ReactNode;
  breadcrumbs?: Crumb[];
  children: React.ReactNode;
}> = ({ title, header, sidebar, right_sidebar, breadcrumbs, children }) => {
  const wide = useMediaQuery(theme.breakpoints.up("md"));

  const mockSidebarItems: SidebarItemData[] = [
    {
      color: "#f8c797",
      label: "START",
      url: "/courses",
      back: true,
    },
    {
      color: "#fad9ba",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#fdecdc",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
  ];

  useEffect(() => {
    if (right_sidebar && (window as any).emgReviewWidget) {
      (window as any).emgReviewWidget.setup({
        targetElementId: "widget-reviews",
        customerId: "2985",
        source: "utbildning.se",
      });
    }
  }, [right_sidebar]);

  return (
    <article>
      <PageWidthContainer>
        <Stack direction={"column"} gap={0}>
          {header && (
            <PageHeader
              config={header}
              title={title}
              height={wide ? 140 : 80}
            />
          )}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            {sidebar && wide && (
              <Box width={228}>
                {sidebar.length > 0 &&
                  sidebar.map((item, i) => (
                    <SidebarItem key={`sidebar-item-{${i}}`} {...item} />
                  ))}
                {sidebar.length === 0 &&
                  mockSidebarItems.map((i) => (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      sx={{
                        backgroundColor: i.color,
                      }}
                    >
                      <SidebarItem {...i} />
                    </Skeleton>
                  ))}
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={"column"}
              flex={1}
              sx={{
                padding: wide ? "11px 37px" : "11px 22px",
              }}
            >
              {breadcrumbs && (
                <Stack
                  direction={"row"}
                  gap={1}
                  style={{
                    color: "#3B3B3B",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontFamily: "Open Sans",
                    fontWeight: "condensed",
                  }}
                >
                  {breadcrumbs.length ? (
                    breadcrumbs.map((crumb, index) => (
                      <React.Fragment key={index}>
                        <Link
                          to={crumb.url || ""}
                          style={{
                            color: "#3B3B3B",
                            textDecoration: "none",
                            fontSize: "12px",
                            fontFamily: "Open Sans",
                            fontWeight: "condensed",
                          }}
                        >
                          {crumb.title}
                        </Link>
                        {index < breadcrumbs.length - 1 && (
                          <span style={{ margin: "0", color: "#3B3B3B" }}>
                            /
                          </span>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Kurser</Link>
                      </Skeleton>
                      <span style={{ margin: "0 5px" }}>/</span>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Öppna utbildningar</Link>
                      </Skeleton>
                      <span style={{ margin: "0 5px" }}>/</span>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Kursnamn</Link>
                      </Skeleton>
                    </>
                  )}
                </Stack>
              )}
              {children}
              {right_sidebar && !wide && (
                <>
                  <Box width="100%" height="1px" bgcolor="#e0e0e0" my={2} />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-around"}
                    width={"100%"}
                  >
                    <RightSidebar>
                      {right_sidebar}
                      <ReviewWidget />
                    </RightSidebar>
                  </Stack>
                </>
              )}
            </Box>
            {right_sidebar && wide && (
              <RightSidebar>
                {right_sidebar}
                <ReviewWidget />
              </RightSidebar>
            )}
          </Stack>
        </Stack>
      </PageWidthContainer>
    </article>
  );
};
