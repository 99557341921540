import React, { useEffect, useCallback } from "react";
import { useStore } from "@feat/common/store/store";
import apiClient from "./client";
import { Bookable, CourseReview, Venue } from "@api/types"; // Ensure this import path is correct
import { CourseWithExtras } from "@feat/common/store/coursesSlice";

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /**
   * COURSES
   */
  const { setFetchingCourses, setCourses, hasFetchedCourses } = useStore();
  const fetchCourses = useCallback(async () => {
    if (hasFetchedCourses) return;
    setFetchingCourses(true);
    console.log("Fetching courses");
    try {
      const response = await apiClient.get<CourseWithExtras[]>("/courses");
      const courses = response.data;

      // Fetch reviews for each course
      const coursesWithReviews = await Promise.all(
        courses.map(async (course) => {
          try {
            const reviewsResponse = await apiClient.get<CourseReview[]>(
              `/courses/${course.id}/reviews`
            );
            return { ...course, reviews: reviewsResponse.data };
          } catch (error) {
            console.error(
              `Error fetching reviews for course ${course.id}:`,
              error
            );
            return { ...course, reviews: [] };
          }
        })
      );

      console.log("Fetched courses with reviews:", coursesWithReviews);
      setCourses(coursesWithReviews);
    } catch (error) {
      console.error("Error fetching courses:", error);
      // You might want to set an error state in your store here
    } finally {
      console.log("Finished fetching courses");
      setFetchingCourses(false);
    }
  }, [setFetchingCourses, setCourses, hasFetchedCourses]);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  /**
   * BOOKABLES
   */
  const { setFetchingBookables, setBookables, hasFetchedBookables } =
    useStore();
  const fetchBookables = useCallback(async () => {
    if (hasFetchedBookables) return;
    setFetchingBookables(true);
    console.log("Fetching bookables");
    try {
      const response = await apiClient.get<Bookable[]>("/bookables");
      console.log("Fetched bookables:", response.data);
      // If there are venues in the venues store, we can add the venue to each bookable
      const venues = useStore.getState().venues;
      if (venues.length > 0) {
        const bookablesWithVenues = response.data.map((bookable) => {
          const venue = venues.find((venue) => venue.id === bookable.venue_id);
          return venue ? { ...bookable, venue } : bookable;
        });
        setBookables(bookablesWithVenues);
      } else {
        setBookables(response.data);
      }
    } catch (error) {
      console.error("Error fetching bookables:", error);
      // You might want to set an error state in your store here
    } finally {
      console.log("Finished fetching bookables");
      setFetchingBookables(false);
    }
  }, [setFetchingBookables, setBookables, hasFetchedBookables]);

  useEffect(() => {
    fetchBookables();
  }, [fetchBookables]);

  /**
   * VENUES
   */
  const { setFetchingVenues, setVenues, hasFetchedVenues } = useStore();
  const fetchVenues = useCallback(async () => {
    if (hasFetchedVenues) return;
    setFetchingVenues(true);
    console.log("Fetching venues");
    try {
      const response = await apiClient.get<Venue[]>("/venues");
      console.log("Fetched venues:", response.data);
      setVenues(response.data);

      // If there are bookables in the bookables store, we can add the venue to each bookable
      if (response.data.length > 0) {
        const bookables = useStore.getState().bookables;
        if (bookables.length > 0) {
          const bookablesWithVenues = bookables.map((bookable) => {
            const venue = response.data.find(
              (venue) => venue.id === bookable.venue_id
            );
            return venue ? { ...bookable, venue } : bookable;
          });
          setBookables(bookablesWithVenues);
        }
      }
    } catch (error) {
      console.error("Error fetching venues:", error);
      // You might want to set an error state in your store here
    } finally {
      console.log("Finished fetching venues");
      setFetchingVenues(false);
    }
  }, [setFetchingVenues, setVenues, hasFetchedVenues, setBookables]);

  useEffect(() => {
    fetchVenues();
  }, [fetchVenues]);

  return <>{children}</>;
};
