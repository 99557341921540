import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

export type SidebarItemData = {
  color: string;
  label: string;
  url?: string;
  onClick?: () => void;
  back?: boolean;
  large?: boolean;
};

type SidebarLinkProps = {
  color: string;
  large?: boolean;
};

const SidebarLink = styled.div<SidebarLinkProps>`
  display: block;
  color: #fff;
  text-decoration: none;
  background-color: ${(props) => props.color};
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => (props.large ? "14px" : "12px")};
  color: #222222;
`;

const SidebarItem: React.FC<SidebarItemData> = ({
  label,
  color,
  url,
  onClick,
  back,
  large,
}) => {
  return (
    <SidebarLink color={color} large={large} onClick={onClick}>
      {url ? (
        <Link
          to={url}
          style={{
            display: "inline-block",
            padding: "12px 24px",
            width: "100%",
          }}
        >
          {back && "\u00AB "}
          {label}
        </Link>
      ) : (
        <>
          {back && "\u00AB "}
          {label}
        </>
      )}
    </SidebarLink>
  );
};

export default SidebarItem;
