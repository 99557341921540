import styled from "@emotion/styled";
import { Typography, useMediaQuery } from "@mui/material";
import PageWidthContainer from "./PageWidthContainer";
import Health from "./Health";
import theme from "src/theme";

const OuterContainer = styled.div`
  width: 100%;
  background: #f7f7f7;
  border-top: 1px solid #bdbdbd;
  margin-top: 128px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin: 50px 0;
`;

const Section = styled.div`
  flex: 1;
  min-width: 200px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  text-decoration-line: none;

  color: #626262;
`;

const footerItems = [
  {
    title: "Retorikutbildning.se Sverige AB",
    content: ["Org.nummer 556828-1942", <Health />],
  },
  {
    title: "Kontakta oss",
    content: [
      <a href="call:+46841004007">08-410 040 07</a>,
      <a href="mailto:mailto:info@retorikutbildning.se">
        info@retorikutbildning.se
      </a>,
    ],
  },
  {
    title:
      "Stockholm | Göteborg | Malmö | Karlstad | Linköping | Luleå | Umeå | Västerås",
    content: [
      "Vi ger anpassade utbildningar i hela Sverige; är din stad inte med på listan ovan så hör gärna av dig så sätter vi din stad på vår bevakningslista.",
    ],
  },
];

export const Footer: React.FC = () => {
  const wide = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <footer>
      <OuterContainer>
        <PageWidthContainer>
          <Container
            style={{
              flexDirection: wide ? "row" : "column",
              gap: wide ? "20px" : "0",
              ...(!wide ? { margin: "2rem" } : {}),
            }}
          >
            {footerItems.map((item) => (
              <Section
                key={item.title}
                style={
                  wide
                    ? {}
                    : {
                        marginTop: "1rem",
                      }
                }
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  {item.title}
                </Typography>

                {item.content.map((content, i) => (
                  <Typography
                    key={`${item.title}-${i}`}
                    variant="body2"
                    paragraph
                  >
                    {content}
                  </Typography>
                ))}
              </Section>
            ))}
          </Container>
        </PageWidthContainer>
      </OuterContainer>
    </footer>
  );
};
