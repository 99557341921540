import { useState } from "react";
import { SectionHeader } from "./SectionHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import { Box } from "@mui/material";

const Section: React.FC<{
  title: string;
  children: React.ReactNode;
  expandable?: boolean;
}> = ({ title, children, expandable }) => {
  const [expanded, setExpanded] = useState(!expandable);

  return (
    <div>
      <SectionHeader
        title={title}
        rightAction={
          (expandable && {
            label: (
              <Fade in={true} timeout={300}>
                {expanded ? (
                  <CloseIcon color="action" />
                ) : (
                  <ExpandMoreIcon color="action" />
                )}
              </Fade>
            ),
            onClick: () => setExpanded(!expanded),
          }) ||
          undefined
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box marginBottom="2rem">{children}</Box>
      </Collapse>
    </div>
  );
};

export default Section;
